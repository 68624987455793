<template>
    <div class="loginMask" @click="maskClick"></div>
    <div id="loginDiv" class="loginDiv">
        <div class="loginContent">
            <div class="leftSide">
                <img :src="imgUrl">
            </div>
            <div class="rightSide">
                <div class="platformLabel">
                    <img src="https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/logo-2021-c.png">
                    {{ platformName }}
                </div>
                <div class="thirdPartLogin">
                    可使用其他平台帳戶快速註冊
                    <img class="socialIcon" :src="imgGoogleUrl">
                    <img class="socialIcon" :src="imgLineUrl">
                    <img class="socialIcon" :src="imgFacebookUrl">
                </div>

                <div class="loginLabel">登入會員</div>
                <div class="inputGroup">
                    <input v-model="loginForm.account" placeholder="請輸入帳號"/>
                </div>
                <div class="inputGroup passwordGroup">
                    <input v-model="loginForm.password" :type="showPassword ? 'text' : 'password'"
                           placeholder="請輸入密碼"/>
                    <div id="passwordLeak" class="passwordToggle fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                         @click="eyeClick"></div>
                </div>
                <div class="forgetPassword">
                    <a @click="ssoLogin" class="ssoLogin">SSO 登入</a>
                    <a href="#" class="forgetLink">忘記密碼</a>
                </div>
                <div class="buttons">
                    <button class="registerButton">點我註冊</button>
                    <button class="loginButton" @click="loginSuccess">登入</button>
                </div>
                <div class="moreInformation">登入即表示您同意牧陽能控平台之帳戶條款和條件並接受隱私權政策。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginComponent',
    props: {
        view: Object,
        platformName: String,
    },
    data() {
        return {
            imgGoogleUrl: require('./svg/Icon_google.svg'),
            imgLineUrl: require('./svg/Icon_line.svg'),
            imgFacebookUrl: require('./svg/Icon_facebook.svg'),
            imgUrl: require('./svg/loginSide.svg'),
            loginForm: {
                account: '',
                password: '',
            },
            showPassword: false,
        }
    },
    methods: {
        maskClick() {
            this.$emit('maskClick')
        },
        eyeClick() {
            this.showPassword = !this.showPassword;
        },
        ssoLogin() {
            location.href = this.view.ssoUrl;
        },
        loginSuccess() {
            this.$emit('loginSuccess')
        },
    },
}
</script>

<style lang="stylus" scoped>
.loginMask
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 9999
    background: hsla(0, 0%, 40%, .8)

.loginDiv
    font-family: Orbitron, Noto Sans TC, sans-serif
    height: 600px
    width: 870px
    position: fixed
    left: calc(50% - 435px)
    top: calc(50% - 300px)
    z-index: 9999
    overflow: hidden

.loginContent
    display: grid
    grid-template-columns: 250px 1fr
    height: 100%

.leftSide
    background: #a8daff
    height: 100%
    position: relative

    img
        pointer-events: none
        position: absolute
        bottom: -50px
        left: 0
        height: 620px

.rightSide
    background: #fff
    padding: 30px 50px 40px 40px
    text-align: left

.platformLabel
    color: #7B7B7B
    font-size: 18px
    margin-bottom: 20px
    letter-spacing: 1px
    height: 36px
    line-height: 36px

    img
        width: 32px
        height: 32px
        float: left
        margin: -2px 10px 0 0

.thirdPartLogin
    color: #a7a7a7
    font-size: 20px
    line-height: 40px
    margin-bottom: 50px
    font-weight: 600
    letter-spacing: 1px

    .socialIcon
        height: 38px
        width: 38px
        cursor: pointer
        float: right
        margin-left: 20px

.loginLabel
    color: #787878
    font-size: 24px
    margin-bottom: 18px
    font-weight: 700
    letter-spacing: 3px

.inputGroup
    margin-bottom: 16px
    position: relative

    input
        width: 100%
        border: none
        border-bottom: solid 1px #d2d2d2
        border-radius: 0
        line-height: 34px
        outline: none
        padding: 8px 0

        &::placeholder
            color: #bdbdbd

.passwordGroup
    .passwordToggle
        position: absolute
        right: 0
        top: 50%
        transform: translateY(-50%)
        cursor: pointer
        color: #20496d

.forgetPassword
    text-align: right
    margin-bottom: 40px

    a
        color: #0f4c81
        font-size: 16px
        line-height: 24px
        text-decoration: underline

        &:hover
            color: #96C800

    .ssoLogin
        margin-right: 25px

.buttons
    margin-bottom: 78px
    height: 55px

    button
        width: 240px
        height: 52px
        float: left
        font-size: 22px
        line-height: 28px
        text-align: center
        letter-spacing: 8px
        border: #96C800 solid 2px
        cursor: pointer
        font-weight: 500

    .registerButton
        margin-right: 40px
        background: #ffffff
        color: #96C800

        &:hover
            background: #efefef
            color: #81ab04

    .loginButton
        color: #ffffff
        background: #96C800

        &:hover
            background: #81ab04

.moreInformation
    clear: both
    text-align: center
    color: #7b7b7b
    font-size: 14px
</style>