export default {
    install: (app) => {
        const apiProxy = new ApiProxy(app)
        const tdeProxy = new TDEngineProxy(app)
        app.config.globalProperties.api = apiProxy
        app.config.globalProperties.TDEngine = tdeProxy
    }
}

import axios from 'axios'
import moment from "moment"

class ApiProxy{
    app = null
    constructor(app){
        this.app = app
    }
    get(url){
        let old_path = [
            '/api/chat/q.json',
            // '/api/device/list.json',
            '/api/field/ids',
            // '/api/field/list',

            // '/api/device_error/list.json',
            // '/api/biogas_maintain/list.json',
            // '/api/cron_item/list.json',
            // '/api/dispatch/list.json',
            // '/api/warranty/list',
            // '/api/issue/list.json',

            '/api/nas_proxy/last_photos',
            '/api/generator/realtime',
            '/api/generator/list',
            '/api/device/anydesk.json',
            '/api/dispatch/list_for_calendar',
            '/api/cron_item/list'
        ]
        old_path.forEach(p=>{
            if (url.startsWith(p)) url = 'https://www.greenshepherd.com.tw' + url
        })
        return axios.get(url)
    }
    patch(url, data){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 65,
            duration: 2.5
        });
        return axios.patch(url, data).then(res=>{
            if (res.data.update === 1) {
                notice.success({
                    title: '已更新',
                });
            }else{
                if (res.data.err) {
                    notice.error({
                        title: res.data.err,
                    });
                }else{
                    notice.info({
                        title: '沒有變更',
                    });
                }
            }
            return res
        }).catch(err=>{
            if (err.response.data.message) {
                notice.info({
                    title: err.response.data.message,
                });
            }else{
                notice.error({
                    title: err.message,
                });
            }
            return err
        })
    }
    post(url, data){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 70,
            duration: 1.8
        });
        return axios.post(url, data).then(res=>{
            if (res.data.message) {
                let msg = res.data.message.replace('login success', '登入成功')
                notice.info({
                    title: msg,
                });
            }else{
                if (res.data.insert > 0){
                    notice.success({
                        title: '已新增',
                    });
                }else{
                    if (res.data.err) {
                        notice.error({
                            title: res.data.err,
                        });
                    }
                }
            }
            return res
        }).catch(err=>{
            console.log(err)
            if (err.response.data.message) {
                notice.info({
                    title: err.response.data.message,
                });
            }else{
                notice.error({
                    title: err.message,
                });
            }
            return err
        })
    }
    delete(url){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 70,
            duration: 1.8
        });
        return axios.delete(url).then(res=>{
            if (res.data.delete > 0){
                notice.success({
                    title: '已刪除',
                });
            }else{
                if (res.data.err) {
                    notice.error({
                        title: res.data.err,
                    });
                }
            }
        });
    }
}
class TDEngineProxy{
    app = null
    constructor(app){
        this.app = app
    }
    get(code){
        let url = 'https://us-central1-greenshepherdcomtw.cloudfunctions.net/td-engine-endpoint?code=' + code
        return axios.get(url)
    }
    post(code){
        let url = 'https://us-central1-greenshepherdcomtw.cloudfunctions.net/td-engine-endpoint?code=' + code
        return axios.post(url)
    }
    async getValueInDate(field_name, datetime){
        let d = datetime
        d.setHours(0, 0, 0, 0)
        let UTCTimeStart = `${d.getUTCFullYear()}-${d.getUTCMonth()+1}-${d.getUTCDate()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`
        d.setHours(23, 59, 59, 999)
        let UTCTimeEnd = `${d.getUTCFullYear()}-${d.getUTCMonth()+1}-${d.getUTCDate()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`
        let code = `select max(value) as max, min(value) as min from db.${field_name} where ts < '${UTCTimeEnd}' and ts >= '${UTCTimeStart}' and value > 0.0`
        return new Promise((resolve, reject)=> {
            this.get(code).then(d=>{
                if (d['data']['data'] && d['data']['data'].length > 0){
                    let max = d['data']['data'][0][0]
                    let min = d['data']['data'][0][1]
                    resolve(max - min)
                }else{
                    reject(0.0)
                }
            }, function(){
                reject(0.0)
            })
        })
    }

    fetchQuarterHourlyData(field_name, datetime){
        return new Promise((resolve, reject) => {
            let startTime = moment(datetime + " 16:00:00").subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            let endTime = moment(datetime + " 16:00:00").format('YYYY-MM-DD HH:mm:ss');
            let url = `select ts, max(val) from db.${field_name} where ts > "${startTime}" and ts < "${endTime}" interval(15m)`;

            this.get(url).then(res => {
                let times = [];
                let datasets = [];
                let expectedTime = new Date(startTime).getTime();
                let endTimeStamp = new Date(endTime).getTime();

                let data = []

                res.data.data.forEach(i => {
                    // 要 -8 小時
                    data.push({
                        time: new Date(i[0]).getTime() - 8 * 3600000,
                        value: i[1]
                    })
                });

                // Fill every 15-minute interval with data
                while (expectedTime < endTimeStamp) {
                    let formattedTime = moment(expectedTime).add(8, 'hours').format('YYYY-MM-DD HH:mm'); // 转换为东八区时间
                    // 找出當前 15分鐘裡的最大值
                    let max = 0
                    data.forEach(i=>{
                        if (i.time >= expectedTime && i.time < expectedTime + 15 * 60 * 1000){
                            if (i.value > max) max = i.value
                        }
                    })

                    times.push(formattedTime);
                    datasets.push(max);
                    expectedTime += 15 * 60 * 1000; // 增加15分钟
                }

                if (res.data.data.length === 0) {
                    reject('No data found'); // 没有数据时拒绝 Promise
                } else {
                    resolve({ times, datasets }); // 成功获取数据时解决 Promise
                }
            }).catch(error => {
                console.error('Error fetching data:', error);
                reject(error); // 网络或其他错误时拒绝 Promise
            });
        });
    }
}
