<template>
    <Apps :platformName="'GS交易平台'"
          @update:userLogout="view.handleUserLogout"
          @update:userInit="view.handleUserInit"
          @userSessionReady="view.attachUserSession"
          style="z-index: 99"
    ></Apps>
    <div id="layout" :content="pageName" :class="{[pageName]: true, 'isMobile': view.isMobile, 'loginPage': !view.isLogin}">
        <div id="nav" :class="{'withTransparent': view.navBgTransparent}">
            <div id="logo" :class="{'isMobile':view.isMobile}" @click="logoClick"></div>
            <div class="iconDropdown" v-show="view.isMobile"></div>
            <div class="menus" v-if="!view.isMobile">
                <div v-for="k in navData" class="mainMenuItem" @click="mainMenuSelect(k)" :class="{active: k.name === view.mainMenuName}" :key="k.name">
                    {{ k.title }}
                </div>

            </div>
            <img class="navMapBg" :src="imgNavUrl" v-show="view.navBgTransparent" />
        </div>
        <Layout id="content">
            {{ view.ssoUrl }}
            <template v-if="view.isLogin">
                <router-view v-if="isRouterAlive"></router-view>
            </template>
        </Layout>
    </div>

    <Drawer :width="260" placement="left" :closable="false" v-model="view.mainMenuDrawer" class-name="mainMenuDrawer">
        <div id="logo" :class="{'isMobile':view.isMobile}" @click="logoClick"></div>
        <ul>
            <li v-for="k in navData" class="mainMenuItem" @click="mainMenuSelect(k)" :class="{active: k.name === view.mainMenuName}" :key="k.name">
                <span>{{ k.title }}</span>
            </li>
        </ul>
    </Drawer>
    <Drawer :title="view.drawerTitle" :width="view.drawerWidth" :closable="false" v-model="view.showDrawer" @on-close="view.drawerName='undefined'">
        <component v-bind:is="view.drawerName"
                   :drawerTitle="view.drawerTitle"
                   :drawerParameter="view.drawerParameter"
                   :drawerName="view.drawerName" :openDrawer="view.openDrawer">
        </component>
    </Drawer>
</template>

<script>
import Swal from "sweetalert2"
import {Apps} from '@/plugins/gs-plugins'

export default {
    name: 'App',
    components: {
        Apps
    },
    setup() {
    },
    computed: {
        navData() {
            if (this.view.isLogin) return this.pageData['nav']
            return []
        },
        pageName() {
            return this.$route.name || this.view.pageName
        },
    },
    data() {
        return {
            isRouterAlive: true,
            isCollapsed: false,
            showApps: false,
            mobileSiderShow: false,
            landingPage: "/map/MapBox",
            appsHover: null,
            imgNavUrl: require('@/assets/svg/map_01n.svg'),
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        this.view.handleWindowResize();
        window.addEventListener('resize', this.view.handleWindowResize);
        this.eventBus.on('userInit', this.userInit)
        this.eventBus.on('afterFieldLoaded', this.afterFieldLoaded)
        window["alert"] = function (msg, timeout, allowOutsideClick) {
            if (typeof allowOutsideClick === "undefined") allowOutsideClick = true;
            Swal.close()
            setTimeout(function () {
                if (timeout !== undefined) {
                    Swal.fire({
                        title: "",
                        html: msg,
                        timer: timeout,
                        showConfirmButton: false,
                        allowOutsideClick: allowOutsideClick,
                    });
                } else {
                    if (msg) {
                        Swal.fire({
                            title: msg,
                        });
                    }
                }
            }, 100)
        }
    },
    methods: {
        logoClick() {
            if (this.view.isMobile)
                this.view.mainMenuDrawer = true
        },
        afterFieldLoaded() {
            // let f = this.view.dataField.filter(x => x.field_name === this.view.selectTarget)
            // if (f.length > 0) this.doSelect(f[0])
        },
        mainMenuSelect(k) {
            this.view.mainMenuName = k.name
            let i = k.items[0]
            this.$router.push(`/${i.dir}/${i.page}`)
        },
        async userInit(data) {
            console.log('userInit', data)
            if (this.view.isLogin) {
                this.view.loadFieldData().then(() => {
                    this.eventBus.emit('afterFieldLoaded')
                //     this.eventBus.emit('afterFieldListChanged')
                //     this.view.loadCaseInfo()
                })
                if (location.pathname === '' || location.pathname === '/'){
                    this.$router.push(this.landingPage)
                }else{
                    this.$router.push(location.pathname)
                }
                this.view.checkEDPAlive()
                setInterval(this.view.checkEDPAlive, this.view.edpCheckTime)
            }
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },
        doSelect(f) {
            this.view.doSelect(f)
            this.$forceUpdate()
        },
    },
}
</script>

<style lang="stylus">
.user-buttons
    margin: 0px 12px 0 12px;
    position: absolute;
    width: 160px;
    height: 70px;
    right: 75px;
    top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    a
        display: inline-block;
        width: 50px;
        height: 40px;
        border-radius: 50%;
        padding-top 0px;
        img
            width: 36px;
            height: 36px;
            cursor: pointer;
.mainMenuDrawer
    .ivu-drawer-content
        background #0f4c81
    ul
        list-style none;
        margin 8px 0 0 8px
        .mainMenuItem
            color #fff
            font-size 18px
            padding 10px 3px 0 0
            margin 0 0 24px 0
            letter-spacing: 6px;
            cursor pointer
            width: auto;
            color #dddddd
            &.active
                span
                    color #fff
                    border-bottom solid 5px #f08200
                    padding: 5px
.navMapBg
    position absolute
    top 0
    left 0
    width 100%
    z-index -1
    height 90px;
    pointer-events none
    opacity: 0.3
</style>
