import {defineStore} from 'pinia';
import axios from "axios";
import {isFloat, isInteger} from "xe-utils";


export const viewState = defineStore('viewState', {
    state: () => {
        return {
            isMobile: true,
            isTablet: false,
            applicationUser: {},
            pageAreaName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            currentPage: null,
            currentDrawer: null,
            currentFieldLat:22.922222,
            currentFieldLon:120.289999,
            caseEquipmentList:[],

            fieldList:[],
            currentFieldId: 425,
            ws: null,

            mainMenuDrawer: false,
            caseInfoList:[],
            selectTargetId:'',
            selectedFieldId:'',
            navBgTransparent: false,
            pageName: '',

            edpStatus: 'offline',
            edpDatetime: 'N/A',
            edpCheckTime: 15,
            edpText: '',
            edpSecondsText: 0,
            userSession: null,
        }
    },
    getters: {
    },
    actions: {
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            // let path=location.pathname
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
            const mobileWidthThreshold = 1024;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.isMobile = true
            }else{
                this.isMobile = false
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(() => {
                this.drawerName = drawerName
            }, 330)
        },
        getDataPoolValue(key){
            return this.dataPool.getValue(`field_${this.currentFieldId}.${key}`)
        },
        checkEDPAlive() {
            this.edpDatetime = this.getDataPoolValue(`datetime`)
            // 每 0.1 秒檢查一次 EDP 狀態, 超過 15 秒沒有回應就視為斷線
            if (this.edpDatetime !== 'N/A'){
                let now = new Date()
                let last = new Date(this.edpDatetime)
                if (now - last > this.edpCheckTime * 1000){
                    this.edpStatus = 'offline'
                    this.edpText = '目前此案場處於 離線 狀態'
                    this.edpSecondsText = "0"
                } else {
                    this.edpStatus = 'online'
                    this.edpText = '目前此案場處於 線上 狀態'
                    this.edpSecondsText = ((now - last) / 1000).toFixed(1)
                }
            }else {
                this.edpStatus = 'offline'
                this.edpText = '目前此案場處於 離線 狀態'
                this.edpSecondsText = "0"
            }
        },
        doSelect(f){
            console.log(f)
        },
        loadFieldData(){
            return new Promise((resolve, reject) => {
                let url = '/api/field/current?size=99999'
                axios.get(url).then((res) => {
                    let d = res.data.data
                    if (Array.isArray(d)) this.fieldList = d
                    this.fieldList.forEach(x => {
                        x.city = x.field_address.substr(0, 3)
                        x.name = x.field_name
                        x.id = x.field_id
                        x.kind = x.field_kind
                        x.address = x.field_address
                        x.lat_lng = x.field_lat_lng
                        x.lat = x.field_lat_lng.split(',')[0]
                        x.lon = x.field_lat_lng.split(',')[1]
                    })
                    resolve()
                }).catch(err => reject(err))
            })
        },
        loadCaseInfo(){
            let ids = this.fieldList.map(x=>x.id)
            let where = `&where={"field_id":"(${ids.join(',')})"}`
            let url = `/api/case_info/get_data?size=99999&${where}`
            axios.get(url).then((res) => {
                console.log(res.data.data)
                if(res.data.data.length>0){
                    this.caseInfoList=res.data.data
                }
            })
        },
        async getTDData(code, successCallback, errorCallback){
            if (code.indexOf('from db. ') > 0){
                if (typeof errorCallback === 'function') errorCallback()
                return false
            }
            let url = 'https://us-central1-greenshepherdcomtw.cloudfunctions.net/td-engine-endpoint?code=' + code
            await axios.get(url).then(res=>{
                if (res.data.desc){
                    console.log(res.data.desc)
                    return;
                }
                if (res.data.status === "error"){
                    if (res.data.desc.indexOf("Table does not exist") >= 0){
                        console.warn('td error, ' + res.data.desc + ', ' + code)
                    }else{
                        console.error('td error, ' + res.data.desc + ', ' + code)
                    }
                    if (typeof errorCallback === 'function') errorCallback(res)
                    return false
                }
                if (typeof successCallback === 'function') successCallback(res.data)
            })
        },
        async getTDValue(code){
            return new Promise((resolve, reject)=> {
                this.getTDData(code, function (d){
                    resolve(d)
                }, function(d){
                    reject(d)
                })
            })
        },
        isNumber(val){
            if(isFloat(val))
                return true
            if(isInteger(val))
                return true
            return false
        },
    },
})

export default {
    install: (app) => {
        const vs = viewState()
        app.config.globalProperties.vs = vs
        app.config.globalProperties.view = vs
    }
}