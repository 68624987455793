import {defineAsyncComponent} from "vue";
import {createRouter, createWebHistory} from "vue-router";

let pageData = {
    'layout': [
        { dir: 'layout', title: '即時', page: 'detailLayout'},
        { dir: 'layout', title: '調度', page: 'dispatchLayout'},
        // { dir: 'layout', title: '調度', page: 'dispatchLayout'},
        // { dir: 'layout', title: '說明', page: 'helpLayout'},
    ],
    'login': [
        {
            title: '登入',
            name: 'user',
            items: [
                { dir: 'user', title: '登入頁面', page: 'Login' },
            ]
        }
    ],
    'nav': [
        {
            title: '地圖',
            name: 'map',
            items: [
                { dir: 'map', title: '案場地圖', page: 'MapBox' },
                { dir: 'map', title: '案場地圖', page: 'FieldMap' }
            ],
        },
        {
            title: '概覽',
            name: 'overview',
            items: [
                { dir: 'overview', title: '儀錶板', page: 'Dashboard' },
                { dir: 'overview', title: '異常事件', page: 'Alarm'},
                // { dir: 'overview', title: '儀錶板-地圖', page: 'DashboardMap' },
                { dir: 'overview', title: '案場基本資料', page: 'System' },
                { dir: 'overview', title: '用戶資料', page: 'User' },
                // { dir: 'overview', title: '用戶管理', page: 'UserManage' },
            ]
        },
        {
            title: '詳情',
            name: 'detail',
            items: [
                { dir: 'detail' ,layout: 'detailLayout', short: '案場', title: '案場詳情摘要', page: 'Summary' },
                { dir: 'detail' ,layout: 'detailLayout', short: '3D', title: '3D地圖', page: 'Field3DMap' },
                { dir: 'detail' ,layout: 'detailLayout', short: '光電', title: '光電', page: 'Solar' },
                { dir: 'detail' ,layout: 'detailLayout', short: '沼氣', title: '沼氣', page: 'GasEngine' },
                { dir: 'detail' ,layout: 'detailLayout', short: '熱泵', title: '熱泵', page: 'HeatPump' },
                { dir: 'detail' ,layout: 'detailLayout', short: '儲能', title: '儲能系統', page: 'Ess' },
                { dir: 'detail' ,layout: 'detailLayout', short: '負載', title: '負載', page: 'Load' },
                { dir: 'detail' ,layout: 'detailLayout', short: '連線', title: '連線狀況', page: 'connInfo' },
                { dir: 'detail' ,layout: 'detailLayout', short: '日誌', title: '日誌記錄', page: 'Logs' },
                { dir: 'detail' ,layout: 'detailLayout', short: '影像', title: '現場影像', page: 'Video' }
            ]
        },
        {
            title: '調度',
            name: 'dispatch',
            items: [
                { dir: 'dispatch', layout: "dispatchLayout", title: '調度指令', page: 'sendCommand' },
                { dir: 'dispatch', layout: "dispatchLayout", title: '調度排程', page: 'calcStrategy' },
                { dir: 'dispatch', layout: "dispatchLayout", title: '今日發電預測', page: 'pvToday' },
                { dir: 'dispatch', layout: "dispatchLayout", title: '發電預測', page: 'pv' },
                { dir: 'dispatch', layout: "dispatchLayout", title: '負載預測', page: 'LoadPredictor' },

                { dir: 'dispatch', title: '天氣預測', page: 'weather' },
                { dir: 'dispatch', title: '資料探索', page: 'DataExplorer' },
                { dir: 'dispatch', title: '統計資料', page: 'statisticsData' },

                { dir: 'dispatch', title: '多能源調度', page: 'simulation' },
                { dir: 'dispatch', title: '能源調度策略', page: 'plot' },
                { dir: 'dispatch', title: '調度記錄頁面', page: 'history' },
                { dir: 'dispatch', title: '事件報告', page: 'eventReport' },
                { dir: 'dispatch', title: '報表匯出', page: 'reportExport' },
                { dir: 'dispatch', title: '報表測試', page: 'Dashboard' },
            ]
        },
        {
            title: '說明',
            name: 'help',
            items: [
                { dir: 'help', title: '平台架構', page: 'DashboardSVG' },
            ]
        },
    ],
    'drawer': [
        { dir: 'drawer', title: '圖表', page: 'Chart' },
        { dir: 'drawer', title: '派工單詳情', page: 'DispatchDetail' },
        { dir: 'drawer', title: '發電機詳情', page: 'GeneratorDetail' },
    ],
    'page': [
        { dir: 'user', title: '通知', page: 'userNotify' },
    ],
}

export default {
    install: (app) => {
        app.config.globalProperties.pageData = pageData
        const routes = [];

        function loadComponent(page, title, dir, parentRoute = null) {
            if (typeof page === "undefined") return false;
            dir = dir || 'page';
            let component = defineAsyncComponent(() =>
                import(`@/page/${dir}/${page}.vue`)
            );
            const route = {
                name: `${dir}_${page}`,
                path: `/${dir}/${page}`, component: component
            };
            if (parentRoute) {
                if (!parentRoute.children) parentRoute.children = [];
                parentRoute.children.push(route);
            } else {
                routes.push(route);
            }
            app.component(dir + '_' + page, component);
        }

        // 先處理 layout，確保它們作為父路由存在
        const parentRoutes = {};
        pageData['layout'].forEach(layoutItem => {
            let component = defineAsyncComponent(() =>
                import(`@/page/${layoutItem.dir}/${layoutItem.page}.vue`)
            );
            const parentRoute = {
                path: `/${layoutItem.dir}/${layoutItem.page}`,
                component: component,
                children: []
            };
            routes.push(parentRoute);
            // 將這些 layout 路由添加到全局變量中，便於後續查找
            parentRoutes[layoutItem.page] = parentRoute;
        });

        console.log(parentRoutes);

        // 處理其他路由
        Object.keys(pageData).forEach(key => {
            if (key === 'layout') return;
            let d = pageData[key];
            d.forEach(i => {
                if (!i.dir) i.dir = key;
                let parentRoute = null;
                if (i.items && i.items.length) {
                    loadComponent(i.page, i.title, i.dir, parentRoute);
                    i.items.forEach(j => {
                        if (!j.dir) j.dir = key;
                        if (j.layout in parentRoutes) parentRoute = parentRoutes[j.layout];
                        loadComponent(j.page, j.title, j.dir, parentRoute);
                    });
                } else {
                    loadComponent(i.page, i.title, i.dir);
                }
            });
        });

        const router = createRouter({
            history: createWebHistory(),
            routes,
        })
        app.use(router);
    }
};